<template>
  <div class="mt-5 md:mt-10 px-0 md:px-4">
    <!-- Loeader -->
    <div v-if="loader" class="h-full w-full">
      <div
        class="
          bg-white bg-opacity-60
          px-8
          py-2
          h-screen
          w-full
          flex
          justify-center
          items-center
        "
      >
        <loader></loader>
      </div>
    </div>
    <!-- Scan QR -->
    <div v-if="!show && !loader" class="text-3xl text-center">
      <div class="w-64 mb-4 pl-5 border-0 mx-auto">
        <button
          @click="onScanPrescription()"
          class="
            border-2 border-solid border-dokBlue-ultra
            text-dokBlue-ultra
            py-3
            px-5
            bg-white
            rounded-cu
            font-bold
            text-lg
            cursor-pointer
            transition
            duration-200
            ease-in-out
            hover:bg-dokBlue-ultra hover:text-white
            font-EffraR
          "
        >
          Scanner Ordonnance
        </button>
      </div>
      <div
        v-if="!destroyed"
        class="border-2 border-solid border-gray-600 mx-auto"
        style="width: 50%"
      >
        <qrcode-stream
          :camera="camera"
          @decode="onDecode"
          @init="onInit"
        ></qrcode-stream>
      </div>
    </div>
    <!-- Display data -->
    <div
      v-if="gdata && show && !loader && destroyed"
      class="flex flex-col justify-between w-auto h-full"
    >
      <header class="border-0 border-b border-solid border-gray-300 md:pb-3">
        <div class="flex justify-between items-center">
          <div class="text-center">
            <div class="text-2xl font-semibold">
              {{
                (gdata.doctor.title ? gdata.doctor.title : "DR") +
                "." +
                gdata.doctor.lastName +
                " " +
                gdata.doctor.firstName
              }}
            </div>
            <div class="text-sm text-gray-700">
              <div class="flex flex-wrap w-full text-md">
                {{
                  gdata.doctor.computed
                    ? gdata.doctor.computed.specialities.length > 0
                      ? gdata.doctor.computed.specialities.join(", ")
                      : ""
                    : ""
                }}
              </div>
            </div>
          </div>
          <img
            alt="logo"
            :src="
              'https://api.dok.ma/uploads/' + gdata.doctor.landingPage.logo
                ? gdata.doctor.landingPage.logo
                : gdata.doctor.computed.brandingLogo
            "
            class="h-12 w-12"
          />
          <div class="text-center">
            <div
              class="text-xs text-gray-800"
              v-if="gdata.doctor.landingPage.header"
            >
              {{ gdata.doctor.landingPage.header }}
            </div>
            <div
              class="text-xs text-gray-800"
              v-else
              v-for="i in gdata.doctor.computed.diplomes"
              :key="i"
            >
              {{ gdata.doctor.computed.diplomes + " ," }}
            </div>
          </div>
        </div>
      </header>
      <div
        class="flex-grow flex-1 flex flex-col mt-5"
        style="min-height: 200px"
      >
        <div class="flex flex-row justify-between mb-10 md:mb-20">
          <div class="text-md">
            Bénéficiaire:<span class="font-bold"
              >{{ gdata.patient.firstName
              }}<span class="uppercase">{{
                " " + gdata.patient.lastName
              }}</span></span
            >-
            <span>{{
              gdata.patient.age ? gdata.patient.age + " ans" : ""
            }}</span>
          </div>
          <div class="text-md">
            Fait le:<span class="font-bold">
              {{ gdata.prescription.date }}
            </span>
          </div>
        </div>
        <div class="">
          <div class="hidden xl:grid gap-x-5 grid-cols-4 mb-3 md:mb-10">
            <div class="font-extrabold text-lg whitespace-no-wrap">
              NOM DU MÉDICAMENT
            </div>
            <div class="font-extrabold text-lg whitespace-no-wrap">
              DURÉE DU TRAITEMENT
            </div>
            <div class="font-extrabold text-lg whitespace-no-wrap">PÉRIODE</div>
            <div class="font-extrabold text-lg whitespace-no-wrap"></div>
          </div>
          <div
            style="min-height: 100px"
            v-for="(presc_medic, index) in gdata.listMed"
            :key="index"
          >
            <div class="grid gap-x-5 grid-cols-4 pb-3">
              <div
                class="
                  flex flex-col
                  text-gray-900 text-normal
                  md:text-lg
                  lg:text-lg
                  capitalize
                  font-bold
                  whitespace-pre-line
                "
              >
                {{ presc_medic.name.toLowerCase() }}
              </div>
              <div class="flex flex-col md:flex-row lg-flex-row">
                <span
                  class="
                    text-gray-900 text-normal
                    md:text-lg
                    lg:text-lg
                    capitalize
                    whitespace-pre-line
                  "
                  >{{ presc_medic.treatmentDuration + " jours" }}</span
                >
                <span
                  class="
                    text-gray-900 text-normal
                    md:text-lg
                    lg:text-lg
                    capitalize
                    whitespace-pre-line
                  "
                  >, {{ presc_medic.dailyDosage + "x/jour" }}</span
                >
              </div>
              <div
                class="
                  text-gray-800 text-normal
                  md:text-lg
                  lg:text-lg
                  whitespace-pre-line
                "
              >
                <span v-for="(item, j) in presc_medic.dayPeriod" :key="j">{{
                  gdata.timesOfTheDay[item.toUpperCase()]["fr"] + ", "
                }}</span>
                {{
                  (presc_medic.timePeriod ? presc_medic.timePeriod : "") +
                  (presc_medic.mealPeriod === "AFTER"
                    ? "/Après repas"
                    : "/Avant repas")
                }}
              </div>
            </div>
            <div>
              <div
                class="text-right aline"
                v-for="(sgrd, index) in gdata.groupedServedDgrus[
                  presc_medic._id
                ]"
                :key="index"
              >
                {{ sgrd.quantity }} Unité vendu le
                <b>{{ moment()(sgrd.created_at).format("DD/MM") }}</b> par
                <b
                  >{{
                    sgrd.pharmacy.localCommercialName
                      ? sgrd.pharmacy.localCommercialName
                      : sgrd.pharmacy.lastName
                      ? sgrd.pharmacy.lastName + " " + sgrd.pharmacy.lastName
                      : "Vous"
                  }},</b
                >
              </div>
              <div class="flex justify-end mb-6">
                <div class="flex items-end">
                  <template v-if="presc_medic.isControlled === true">
                    <div class="mr-2 w-20 md:w-32 lg:w-32">
                      <label class="block text-gray-700 text-md font-bold mb-2"
                        >Nom</label
                      >
                      <input
                        :id="'medical_' + presc_medic._id + '_buyername'"
                        class="
                          shadow
                          appearance-none
                          border
                          rounded
                          w-full
                          py-2
                          px-3
                          text-gray-700
                          leading-tight
                          focus:outline-none focus:shadow-outline
                        "
                        type="text"
                        placeholder="Nom"
                      />
                    </div>
                    <div class="mr-2 w-20 md:w-32 lg:w-32">
                      <label class="block text-gray-700 text-md font-bold mb-2"
                        >CIN</label
                      >
                      <input
                        :id="'medical_' + presc_medic._id + '_buyercin'"
                        class="
                          shadow
                          appearance-none
                          border
                          rounded
                          w-full
                          py-2
                          px-3
                          text-gray-700
                          leading-tight
                          focus:outline-none focus:shadow-outline
                        "
                        type="text"
                        placeholder="CIN"
                      />
                    </div>
                  </template>
                  <div class="mr-2 w-20 md:w-32 lg:w-32">
                    <label class="block text-gray-700 text-md font-bold mb-2"
                      >Qantité</label
                    >
                    <input
                      :id="'medical_' + presc_medic._id + '_qty'"
                      min="0"
                      step="1"
                      class="
                        shadow
                        appearance-none
                        border
                        rounded
                        w-full
                        py-2
                        px-3
                        text-gray-700
                        leading-tight
                        focus:outline-none focus:shadow-outline
                      "
                      type="number"
                      placeholder="Qty"
                    />
                  </div>
                  <div class="mr-2 w-20 md:w-32 lg:w-32">
                    <label class="block text-gray-700 text-md font-bold mb-2"
                      >Prix Unit</label
                    >
                    <input
                      :id="'medical_' + presc_medic._id + '_price'"
                      min="0"
                      step="1"
                      class="
                        shadow
                        appearance-none
                        border
                        rounded
                        w-full
                        py-2
                        px-3
                        text-gray-700
                        leading-tight
                        focus:outline-none focus:shadow-outline
                      "
                      type="number"
                      placeholder="Prix"
                    />
                  </div>
                  <div>
                    <button
                      @click="serveMed(presc_medic)"
                      class="
                        bg-gray-300
                        hover:bg-gray-400
                        rounded
                        py-2
                        border-0
                        px-2
                        cursor-pointer
                      "
                    >
                      <i class="fas fa-stamp"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr class="border-gray-400" />
          </div>
        </div>
      </div>
      <footer class="border-0 border-t border-solid border-gray-300 pt-3">
        <div class="flex flex-col">
          <div
            v-if="gdata.doctor.landingPage.footer"
            class="text-base text-gray-800 uppercase"
          >
            {{ gdata.doctor.landingPage.footer }}
          </div>
          <div v-else class="text-base text-gray-800 uppercase">
            {{ gdata.doctor.computed.fullAddress }}
          </div>
          <div v-if="gdata.doctor.fix" class="font-bold text-gray-900">
            FIX:<span class="text-base text-gray-800">
              {{ gdata.doctor.fix }}</span
            >
          </div>
        </div>
        <div class="flex mt-5">
          <button
            @click="
              show = false;
              gdata = null;
            "
            class="
              w-full
              md:w-8/12 md:mx-auto
              border-2 border-solid border-dokBlue-ultra
              text-dokBlue-ultra
              py-3
              px-5
              bg-white
              rounded-cu
              font-bold
              text-lg
              cursor-pointer
              transition
              duration-200
              ease-in-out
              hover:bg-dokBlue-ultra hover:text-white
              font-EffraR
            "
          >
            Términer
          </button>
        </div>
      </footer>
    </div>
    <!-- Null Data patients -->
    <div
      v-if="!gdata && show && !loader && destroyed"
      class="
        bg-white
        0
        px-8
        py-
        w-full
        flex flex-col
        justify-center
        items-center
      "
    >
      <img alt="vide" src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
      <button
        @click="show = false"
        class="
          w-full
          md:w-8/12 md:mx-auto
          border-2 border-solid border-dokBlue-ultra
          text-dokBlue-ultra
          py-3
          px-5
          bg-white
          rounded-cu
          font-bold
          text-lg
          cursor-pointer
          transition
          duration-200
          ease-in-out
          hover:bg-dokBlue-ultra hover:text-white
          font-EffraR
        "
      >
        Retourner
      </button>
    </div>
  </div>
</template>
<script>
import { QrcodeStream } from "vue-qrcode-reader";
const loader = () => import("@/views/global-components/loader");
import moment from "moment";
export default {
  components: { QrcodeStream, loader },
  data() {
    return {
      loader: false,
      show: false,
      camera: "off",
      idPrescription: "",
      destroyed: true,
      gdata: null,
    };
  },
  methods: {
    moment() {
      return moment;
    },
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },
    onScanPrescription: function () {
      if (this.destroyed) {
        this.destroyed = false;
        this.camera = "auto";
      } else {
        this.destroyed = true;
        this.camera = "off";
      }
    },
    async onDecode(decodeString) {
      try {
        this.loader = true;
        this.destroyed = true;
        this.idPrescription = decodeString;
        this.camera = "off";
        await this.$store.dispatch("pharmacy/GET_PRESCRIPTION", {
          id: this.idPrescription,
          category: "DRUG",
          onData: this.onPrescriptionData,
        });
      } finally {
        this.show = true;
        this.loader = false;
      }
    },
    onPrescriptionData: function (data) {
      if (data.prescription) this.gdata = data;
    },
    async serveMed(drug) {
      try {
        this.loader = true;
        var drug_id = drug._id;
        var serve_data = {
          drug_id: drug_id,
          name: drug.name,
          isControlled: drug.isControlled,
          quantity: "",
          price: "",
          buyer: { name: "", cin: "" },
          prescription: this.gdata.prescription._id,
        };
        serve_data.quantity =
          document.getElementById("medical_" + drug_id + "_qty").value == null
            ? ""
            : Number(
                document.getElementById("medical_" + drug_id + "_qty").value
              );
        serve_data.price =
          document.getElementById("medical_" + drug_id + "_price").value == null
            ? ""
            : Number(
                document.getElementById("medical_" + drug_id + "_price").value
              );
        if (serve_data.quantity == "" || serve_data.price == "") {
          return this.$vs.notify({
            time: 4000,
            text: "Veuillez remplire les champs quantité et Prix.",
            color: "#ca0b00",
            iconPack: "feather",
            icon: "icon-slach",
          });
        } else {
          if (drug.isControlled) {
            serve_data.buyer.name =
              document.getElementById("medical_" + drug_id + "_buyername") !=
              null
                ? String(
                    document.getElementById("medical_" + drug_id + "_buyername")
                      .value
                  )
                : "";
            serve_data.buyer.cin =
              document.getElementById("medical_" + drug_id + "_buyername") !=
              null
                ? String(
                    document.getElementById("medical_" + drug_id + "_buyercin")
                      .value
                  )
                : "";
            if (serve_data.buyer.name == "" || serve_data.buyer.cin == "") {
              return this.$vs.notify({
                time: 4000,
                text: "Veuillez remplire les champs nom et CIN.",
                color: "#ca0b00",
                iconPack: "feather",
                icon: "icon-slach",
              });
            }
          }
        }
        this.$store.dispatch("pharmacy/SERVE_DRUG_PRESCRIPTION", {
          data: serve_data,
          onData: this.servedMed,
        });
      } finally {
        this.loader = false;
      }
    },
    servedMed(data, drug_id) {
      if (data.ok) {
        if (this.gdata.groupedServedDgrus[drug_id])
          this.gdata.groupedServedDgrus[drug_id].push(data.data);
        else {
          this.gdata.groupedServedDgrus[drug_id] = [];
          this.gdata.groupedServedDgrus[drug_id].push(data.data);
        }
        document.getElementById("medical_" + drug_id + "_qty").value = null;
        document.getElementById("medical_" + drug_id + "_price").value = null;
        return this.$vs.notify({
          time: 4000,
          text: "Effectuer avec succes",
          color: "#4BB543",
          iconPack: "feather",
          icon: "icon-slach",
        });
      } else {
        return this.$vs.notify({
          time: 4000,
          text: data.message,
          color: "#ca0b00",
          iconPack: "feather",
          icon: "icon-slach",
        });
      }
    },
  },
};
</script>
